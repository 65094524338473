/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';

// components
import { GalleryImage } from '../galleries/GalleryImage';

export const Pagination = ({
	data,
	display,
	scrollPosition,
	modal,
	setModal,
	setModalImage,
	setModalData,
	handleScroll,
}) => {
	// Products per page
	const PHOTOS_PER_PAGE = 48;

	// State for managing current page and products on that page
	const [currentPage, setCurrentPage] = useState(1);
	const [currentPhoto, setCurrentPhoto] = useState([]);

	// Calculate total pages based on products length
	const totalPages = Math.ceil(data?.length / PHOTOS_PER_PAGE);

	// Next page handler
	const handleNextPage = () => {
		handleScroll();
		if (currentPage < totalPages) setCurrentPage(currentPage + 1);
	};

	// Previous page handler
	const handlePreviousPage = () => {
		handleScroll();
		if (currentPage > 1) setCurrentPage(currentPage - 1);
	};

	// Reset currentPage to 1 whenever products change
	useEffect(() => {
		setCurrentPage(1);
	}, [data]);

	// Update current products based on the current page
	useEffect(() => {
		const startIndex = (currentPage - 1) * PHOTOS_PER_PAGE;
		const endIndex = startIndex + PHOTOS_PER_PAGE;
		setCurrentPhoto(data?.slice(startIndex, endIndex));
	}, [currentPage, data]);

	return (
		<>
			<div className={`gallery-container ${display}`}>
				{currentPhoto?.map((image) => (
					<div className="image-box" key={image._id}>
						<GalleryImage
							image={image}
							scrollPosition={scrollPosition}
							modal={modal}
							setModal={setModal}
							setModalImage={setModalImage}
							setModalData={setModalData}
						/>
					</div>
				))}
			</div>

			{totalPages > 1 && (
				<div className="pagination-controls">
					<button
						className="btn"
						onClick={handlePreviousPage}
						// disabled={currentPage === 1}
					>
						Previous
					</button>
					<span>
						Page {currentPage} of {totalPages}
					</span>
					<button
						className="btn"
						onClick={handleNextPage}
						// disabled={currentPage === totalPages}
					>
						Next
					</button>
				</div>
			)}
		</>
	);
};
